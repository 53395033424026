import { gql, useQuery } from '@apollo/client';
import { useTeam } from 'context/TeamProvider';
import { NexoyaImpactGroupRule } from '../../types';

export const IMPACT_GROUP_RULES_QUERY = gql`
  query ImpactGroupRules($portfolioId: Int!, $teamId: Int!) {
    portfolioV2(portfolioId: $portfolioId, teamId: $teamId) {
      impactGroupRules {
        impactGroupRuleId
        name
        impactGroupId
        appliedDiscoveredContents {
          discoveredContentId
          content {
            contentId
            title
            provider {
              provider_id
              name
            }
            contentType {
              name
            }
            parent {
              title
            }
          }
          impactGroupRules {
            impactGroupRule {
              impactGroupId
              impactGroupRuleId
              name
            }
          }
        }
        filters {
          providers {
            adAccountIds
            providerId
          }
          contentFilters {
            fieldName
            operator
            value {
              date
              number
              numberArr
              string
              stringArr
            }
          }
        }
      }
    }
  }
`;
type ImpactGroupRuleQueryVariables = {
  teamId: number;
  portfolioId: number;
};

export function useImpactGroupRuleQuery({ portfolioId }: { portfolioId: number }) {
  const { teamId } = useTeam();

  const query = useQuery<
    {
      portfolioV2: {
        impactGroupRules: NexoyaImpactGroupRule[];
      };
    },
    ImpactGroupRuleQueryVariables
  >(IMPACT_GROUP_RULES_QUERY, {
    skip: !portfolioId,
    variables: {
      teamId,
      portfolioId,
    },
  });

  return query;
}
