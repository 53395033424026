import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  NexoyaContentRuleEditInput,
  NexoyaUpdateContentRuleAndContentsMutationResponse,
  NexoyaUpdateContentRuleUserChoiceInput,
} from '../../types';
import { CONTENT_RULES_QUERY } from './queryContentRules';
import { useTeam } from '../../context/TeamProvider';
import { PORTFOLIO_QUERY } from '../portfolio/queryPortfolio';
import { StringParam, useQueryParams } from 'use-query-params';
import { UTC_TIMEZONE } from '../../utils/dates';

const UPDATE_CONTENT_RULE_AND_CONTENTS_MUTATION = gql`
  mutation UpdateContentRuleAndContents(
    $contentRuleEdit: ContentRuleEditInput!
    $contentRuleId: Float!
    $portfolioId: Float!
    $teamId: Float!
    $userChoices: [UpdateContentRuleUserChoiceInput]
  ) {
    updateContentRuleAndContents(
      contentRuleEdit: $contentRuleEdit
      contentRuleId: $contentRuleId
      portfolioId: $portfolioId
      teamId: $teamId
      userChoices: $userChoices
    ) {
      updatedContentRule {
        name
        appliedDiscoveredContents {
          discoveredContentId
        }
      }
    }
  }
`;

type UpdateContentRuleProps = {
  teamId: number;
  portfolioId: number;
  contentRuleId: number;
  contentRuleEdit: NexoyaContentRuleEditInput;
  userChoices?: NexoyaUpdateContentRuleUserChoiceInput[];
};

export function useUpdateContentRuleMutation({
  portfolioId,
  onCompleted,
}: {
  portfolioId: number;
  onCompleted?: (data: { updateContentRuleAndContents: NexoyaUpdateContentRuleAndContentsMutationResponse }) => void;
}) {
  const { teamId } = useTeam();
  const [qp] = useQueryParams({
    dateFrom: StringParam,
    dateTo: StringParam,
  });

  return useMutation<
    { updateContentRuleAndContents: NexoyaUpdateContentRuleAndContentsMutationResponse },
    UpdateContentRuleProps
  >(UPDATE_CONTENT_RULE_AND_CONTENTS_MUTATION, {
    awaitRefetchQueries: true,
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onCompleted: (data) => {
      if (data?.updateContentRuleAndContents) {
        const rule = data.updateContentRuleAndContents.updatedContentRule;
        const contentCount = rule?.appliedDiscoveredContents?.length || 0;

        toast.success(`Content rule ${rule?.name} updated successfully`, {
          description: `The content rule has ${contentCount} matching contents`,
        });
      } else {
        toast.error('Failed to update content rule');
      }
      onCompleted?.(data);
    },
    refetchQueries: [
      {
        notifyOnNetworkStatusChange: true,
        query: CONTENT_RULES_QUERY,
        variables: {
          teamId,
          portfolioId,
        },
        fetchPolicy: 'network-only',
      },
      {
        query: PORTFOLIO_QUERY,
        variables: {
          teamId,
          portfolioId,
          withBudget: false,
          dateFrom: qp.dateFrom + UTC_TIMEZONE,
          dateTo: qp.dateTo + UTC_TIMEZONE,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
}
