import { NexoyaDailyMetric, NexoyaDailyProviderMetric, NexoyaFunnelStepPerformance } from '../../../types';

import { useLabels } from '../../../context/LabelsProvider';
import { usePortfolio } from '../../../context/PortfolioProvider';

import {
  getDailyValuePerImpactGroup,
  getDailyValuePerLabel,
  getDailyValuePerProvider,
  getFilterSource,
  getOptiDailyValuePerImpactGroup,
  getOptiDailyValuePerLabel,
  getOptiDailyValuePerProvider,
} from '../../../utils/provider';
import { isInTheFuture, isToday } from 'utils/dates';
import { useImpactGroups } from '../../../context/ImpactGroupsProvider';

interface PerformanceChartDataPoint {
  timestamp?: string;
  value?: number;
  timestampComparison?: string;
  valueTimeComparison?: number;
  comparisonChangePercent?: number;
}

export function usePortfolioToPerformanceChartData(
  isActivePortfolio: boolean,
  selectedFunnelStep: NexoyaFunnelStepPerformance,
) {
  const {
    providers: { providersFilter },
    performanceChart: { isStackedAreaChartActive, compareTo: compareToRestOfData },
  } = usePortfolio();
  const {
    filter: { labelsFilter },
  } = useLabels();

  const {
    filter: { impactGroupsFilter },
  } = useImpactGroups();

  if (!selectedFunnelStep) {
    return {
      isActivePortfolio: null,
      dataForChart: null,
      realizedMetricDataPast: null,
      validationDataFormatted: null,
      funnelSteps: null,
      validationTooltip: null,
      selectedFunnelStep: null,
    };
  }

  const filteredProviderIds = providersFilter.map((provider) => provider.provider_id);
  const filteredLabelIds = labelsFilter.map((label) => label.labelId);
  const filteredImpactGroupIds = impactGroupsFilter.map((impactGroup) => impactGroup.impactGroupId);

  const dailyMetrics: NexoyaDailyMetric[] = selectedFunnelStep?.dailyMetrics || [];
  const optimizationData = optimizationForGivenDate();

  const comparisonPerformanceChartData = (() => {
    // Step 1: Separate data points with 'timestamp' and 'value'
    const timestampData: PerformanceChartDataPoint[] = dailyMetrics.map((dailyMetric) => {
      const providerSource = getFilterSource(
        dailyMetric,
        false,
        false,
        filteredProviderIds,
        filteredLabelIds,
        filteredImpactGroupIds,
      );

      const value =
        providerSource.reduce((acc, providerMetric) => acc + (providerMetric?.value?.value || 0), 0) || null;

      return {
        timestamp: dailyMetric.day ? dailyMetric.day?.substring(0, 10) : null,
        value,
      };
    });

    // Step 1: Separate data points with 'timestampComparison' and 'valueTimeComparison'
    const timestampComparisonData: PerformanceChartDataPoint[] = dailyMetrics.map((dailyMetric) => {
      const providerSource = getFilterSource(
        dailyMetric,
        false,
        false,
        filteredProviderIds,
        filteredLabelIds,
        filteredImpactGroupIds,
      );

      const comparisonValue = providerSource.reduce(
        (acc, providerMetric) => acc + (providerMetric.comparisonValue?.value || 0),
        0,
      );

      return {
        timestampComparison: dailyMetric.comparisonDay ? dailyMetric.comparisonDay?.substring(0, 10) : null,
        valueTimeComparison: comparisonValue || null,
      };
    });

    // Step 2: Merge the data points in order
    const maxLength = Math.max(timestampData.length, timestampComparisonData.length);
    const mergedData: PerformanceChartDataPoint[] = [];

    for (let i = 0; i < maxLength; i++) {
      const dataPoint: PerformanceChartDataPoint = {
        timestamp: null,
        value: null,
        timestampComparison: null,
        valueTimeComparison: null,
        comparisonChangePercent: null,
      };

      if (timestampData[i]) {
        dataPoint.timestamp = timestampData[i].timestamp;
        dataPoint.value = timestampData[i].value;
      }

      if (timestampComparisonData[i]) {
        dataPoint.timestampComparison = timestampComparisonData[i].timestampComparison;
        dataPoint.valueTimeComparison = timestampComparisonData[i].valueTimeComparison;
      }

      // Step 3: Compute 'comparisonChangePercent' if both values are present
      if (dataPoint.value != null && dataPoint.valueTimeComparison != null) {
        dataPoint.comparisonChangePercent =
          dataPoint.valueTimeComparison !== 0
            ? ((dataPoint.value - dataPoint.valueTimeComparison) / dataPoint.valueTimeComparison) * 100
            : 0;
      }

      mergedData.push(dataPoint);
    }

    // Step 4: Filter out invalid data points if necessary
    return mergedData.filter((chartDataPoint) => {
      const isValueValid =
        chartDataPoint.value == null || (!isNaN(chartDataPoint.value) && chartDataPoint.value !== Infinity);
      const isComparisonValueValid =
        chartDataPoint.valueTimeComparison == null ||
        (!isNaN(chartDataPoint.valueTimeComparison) && chartDataPoint.valueTimeComparison !== Infinity);

      return isValueValid && isComparisonValueValid;
    });
  })();

  function optimizationForGivenDate() {
    const targetPerformanceData = selectedFunnelStep?.optimizationMetricTotals;
    return {
      optimizationPotentialValue: targetPerformanceData?.expectedTotal,
      optimizationPotentialPercentage: targetPerformanceData?.potentialPercentage,
    };
  }
  const getDataForChart = () => {
    const realizedData = dailyMetrics.map((dailyMetric) => {
      const providerSource = getFilterSource(
        dailyMetric,
        isStackedAreaChartActive,
        compareToRestOfData,
        filteredProviderIds,
        filteredLabelIds,
        filteredImpactGroupIds,
      );

      const value = providerSource.reduce(
        (acc: number, providerMetric: NexoyaDailyProviderMetric) => acc + providerMetric.value?.value,
        0,
      );

      return {
        timestamp: dailyMetric.day,
        value,
        ...getDailyValuePerProvider(dailyMetric),
        ...getDailyValuePerLabel(dailyMetric),
        ...getDailyValuePerImpactGroup(dailyMetric),
        ...optimizationData,
      };
    });

    const optimizationMetrics = (selectedFunnelStep?.dailyOptimizationMetrics || []).map((dailyMetric) => {
      const filteredProviders = getFilterSource(
        dailyMetric,
        isStackedAreaChartActive,
        compareToRestOfData,
        filteredProviderIds,
        filteredLabelIds,
        filteredImpactGroupIds,
      );

      let baselinePerformanceRelative = 0;
      let expectedPerformanceRelative = 0;

      filteredProviders?.forEach((providerMetric) => {
        baselinePerformanceRelative += providerMetric.relativeBaseline;
        expectedPerformanceRelative += providerMetric.relativeExpected;
      });

      return {
        timestamp: dailyMetric.day?.substring(0, 10),
        baselinePerformanceRelative,
        expectedPerformanceRelative,
        ...getOptiDailyValuePerProvider(dailyMetric),
        ...getOptiDailyValuePerLabel(dailyMetric),
        ...getOptiDailyValuePerImpactGroup(dailyMetric),
      };
    });

    // Check if `expectedPerformanceRelative` is same for all days
    const allSamePerformance = optimizationMetrics.every(
      (item) => item.expectedPerformanceRelative === optimizationMetrics[0].expectedPerformanceRelative,
    );
    let expectedData: string | any[];

    if (allSamePerformance) {
      // create a copy of the original object to avoid directly mutating original data
      const firstItem = { ...optimizationMetrics[0] };
      firstItem.baselinePerformanceRelative = firstItem.expectedPerformanceRelative;

      expectedData = [firstItem, optimizationMetrics[optimizationMetrics.length - 1]];
    } else {
      expectedData = optimizationMetrics;
    }

    function mergeRealizedDataAndExpectedData(realizedData, expectedData) {
      if (isToday(expectedData?.[0]?.timestamp) || isInTheFuture(expectedData?.[0]?.timestamp)) {
        // if optimization starts today or tomorrow, we artificially extend metric data past,
        // so there is no gap between the data points of two chart lines
        realizedData.push({
          timestamp: expectedData?.[0]?.timestamp,
          value: expectedData?.[0]?.expectedPerformanceRelative,
          optimizationTooltipDisabled: true,
        });
      }

      return [...realizedData, ...expectedData];
    }

    return expectedData?.length ? mergeRealizedDataAndExpectedData(realizedData, expectedData) : realizedData;
  };

  return {
    isActivePortfolio,
    dataForChart: getDataForChart(),
    realizedMetricDataPast: dailyMetrics,
    comparisonPerformanceChartData,
    validationDataFormatted: null,
    validationTooltip: null,
    selectedFunnelStep,
  };
}
