import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  NexoyaImpactGroupRuleEditInput,
  NexoyaUpdateImpactGroupRuleAndContentsMutationResponse,
  NexoyaUpdateImpactGroupRuleUserChoiceInput,
} from '../../types';
import { useTeam } from '../../context/TeamProvider';
import { IMPACT_GROUP_RULES_QUERY } from './queryImpactGroupRules';

const UPDATE_IMPACT_GROUP_RULE_AND_CONTENTS_MUTATION = gql`
  mutation UpdateImpactGroupRuleAndContents(
    $impactGroupRuleEdit: ImpactGroupRuleEditInput!
    $impactGroupRuleId: Float!
    $portfolioId: Float!
    $teamId: Float!
    $userChoices: [UpdateImpactGroupRuleUserChoiceInput]
  ) {
    updateImpactGroupRuleAndContents(
      impactGroupRuleEdit: $impactGroupRuleEdit
      impactGroupRuleId: $impactGroupRuleId
      portfolioId: $portfolioId
      teamId: $teamId
      userChoices: $userChoices
    ) {
      updatedImpactGroupRule {
        name
        appliedDiscoveredContents {
          discoveredContentId
        }
      }
    }
  }
`;

type UpdateImpactGroupRuleProps = {
  teamId: number;
  portfolioId: number;
  impactGroupRuleId: number;
  impactGroupRuleEdit: NexoyaImpactGroupRuleEditInput;
  userChoices?: NexoyaUpdateImpactGroupRuleUserChoiceInput[];
};

export function useUpdateImpactGroupRuleMutation({
  portfolioId,
  onCompleted,
}: {
  portfolioId: number;
  onCompleted?: () => void;
}) {
  const { teamId } = useTeam();

  return useMutation<
    { updateImpactGroupRuleAndContents: NexoyaUpdateImpactGroupRuleAndContentsMutationResponse },
    UpdateImpactGroupRuleProps
  >(UPDATE_IMPACT_GROUP_RULE_AND_CONTENTS_MUTATION, {
    awaitRefetchQueries: true,
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onCompleted: (data) => {
      if (data?.updateImpactGroupRuleAndContents) {
        const rule = data.updateImpactGroupRuleAndContents.updatedImpactGroupRule;
        const contentCount = rule?.appliedDiscoveredContents?.length || 0;

        toast.success(`Impact group rule ${rule?.name} updated successfully`, {
          description: `The impact group rule has ${contentCount} matching contents`,
        });
        onCompleted();
      } else {
        toast.error('Failed to update impact group rule');
      }
    },
    refetchQueries: [
      {
        notifyOnNetworkStatusChange: true,
        query: IMPACT_GROUP_RULES_QUERY,
        variables: {
          teamId,
          portfolioId,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
}
