import React, { FC, useEffect, useRef, useState } from 'react';
import { capitalize, concat, toNumber } from 'lodash';
import { FunnelContainerStyled, FunnelStepsContainerStyled, LabelsContainerStyled } from '../../Funnel/styles';
import { FunnelSteps } from '../../Funnel/components/FunnelSteps';
import { FunnelData } from '../../Funnel/MultiSeriesFunnel';
import { AssignMetricFunnelStepLabel } from './assignment/AssignMetricFunnelStepLabel';
import {
  NexoyaApplicableContentRule,
  NexoyaContentRule,
  NexoyaContentRuleFunnelStepMappingInput,
  NexoyaConversion,
  NexoyaDiscoveredContentStatus,
  NexoyaFunnelStepMappingInput,
  NexoyaFunnelStepMappingType,
  NexoyaFunnelStepV2,
  NexoyaMeasurement,
} from '../../../../../types';
import { usePortfolio } from '../../../../../context/PortfolioProvider';
import { MEASUREMENTS_QUERY, useMeasurementsQuery } from '../../../../../graphql/measurement/queryMeasurements';
import { useTranslationsQuery } from '../../../../../graphql/translation/queryTranslations';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../components-ui/Popover';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../../../../components-ui/Command';
import translate from '../../../../../utils/translate';
import { nexyColors } from '../../../../../theme';
import RadioGroup from '../../../../../components/RadioGroup';
import FormControlLabel from '../../../../../components/FormControlLabel';
import Radio from '../../../../../components/Radio';
import { UTMTracking } from './assignment/UTMTracking';
import { cn } from '../../../../../lib/utils';
import SidePanel, { SidePanelActions } from '../../../../../components/SidePanel';
import ButtonAsync from '../../../../../components/ButtonAsync';
import Button from '../../../../../components/Button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../../../components-ui/AlertDialog';
import { useDialogState } from '../../../../../components/Dialog';
import { LabelLight } from '../../../../../components/InputLabel/styles';
import { getAssignedMetricBasedOnMappingType } from './utils';
import { useContentMappingStore } from '../../../../../store/content-metric-assignments';
import { useLazyQuery } from '@apollo/client';
import { LIST_CONVERSIONS_QUERY } from 'graphql/portfolioRules/queryConversions';
import { useTeam } from '../../../../../context/TeamProvider';
import { useRouteMatch } from 'react-router';
import { useSetContentRuleMappingMutation } from '../../../../../graphql/portfolioRules/mutationContentRuleMapping';
import { toast } from 'sonner';
import { MetricClashesDialog } from './MetricClashes';
import { useRuleClashStore } from '../../../../../store/metric-clashes';
import { useApplyRulesToDiscoveredContentsMutation } from '../../../../../graphql/portfolioRules/mutationApplyRulesToDiscoveredContents';
import { usePortfolioDefaultFunnelStepMappingsQuery } from '../../../../../graphql/portfolioRules/queryPortfolioDefaultFunnelStepMappings';
import { useUpdateContentRuleMutation } from '../../../../../graphql/portfolioRules/mutationUpdateContentRule';
import { CustomConversions } from './assignment/CustomConversions';

const GA4_PROVIDER_ID = 44;
const DEFAULT_COLOR = nexyColors.azure;
const INITIAL_DATA: FunnelData = {
  labels: [],
  subLabels: [],
  values: [],
  colors: [],
};

export const ASSIGN_METRIC_OPTIONS = (customConversionsDisabled: boolean) => [
  { value: NexoyaFunnelStepMappingType.Metric, label: 'Assign metric', disabled: false },
  { value: NexoyaFunnelStepMappingType.Conversion, label: 'Custom conversions', disabled: customConversionsDisabled },
  { value: NexoyaFunnelStepMappingType.Utm, label: 'UTM tracking', disabled: false },
  { value: NexoyaFunnelStepMappingType.Ignore, label: 'Ignore mapping', disabled: false },
];

const cleanUtmParams = (params: any[] = []) =>
  params?.filter((p) => p.type && p.values).map(({ type, values }) => ({ type, values }));

export interface AssignedMetric extends NexoyaFunnelStepMappingInput {
  funnelStepId: number;
}

interface Props {
  funnelSteps: NexoyaFunnelStepV2[];
  isOpen: boolean;
  closeSidePanel: () => void;
  contentRule?: NexoyaContentRule;
}

export const ContentMetricAssignment: FC<Props> = ({ isOpen, closeSidePanel, funnelSteps, contentRule }) => {
  const didFetch = useRef(false);

  const match = useRouteMatch();
  const portfolioId = parseInt(match.params.portfolioID, 10);

  const { teamId } = useTeam();
  const { selectedFunnelStep, setSelectedFunnelStep } = usePortfolio().selectedFunnelStep;
  const selectedFunnelStepId = selectedFunnelStep?.funnel_step_id;

  const providerId = contentRule?.filters?.providerId;
  const adAccountIds = contentRule?.filters?.adAccountIds;

  const [funnelData, setFunnelData] = useState<FunnelData>(INITIAL_DATA);
  const [assignedMetrics, setAssignedMetrics] = useState<AssignedMetric[]>([]);
  const [isAssignMetricsOpen, setIsAssignMetricsOpen] = useState(false);

  usePortfolioDefaultFunnelStepMappingsQuery({
    portfolioId,
    providerId,
    onCompleted: (data) => {
      if (!data.listPortfolioDefaultFunnelStepMappings) return;

      const defaultMappings = data.listPortfolioDefaultFunnelStepMappings;
      setAssignedMetrics((prev) => {
        const newMappings = defaultMappings.map((mapping) => ({
          funnelStepId: mapping.funnelStepId,
          metricId: mapping.metricId,
          type: mapping.metricId ? NexoyaFunnelStepMappingType.Metric : NexoyaFunnelStepMappingType.Ignore,
          conversionId: null,
        }));

        if (contentRule.funnelStepMappings.length === 0) {
          return newMappings; // Use defaults if no existing mappings
        } else {
          return prev; // Keep existing mappings if any exist
        }
      });
    },
  });
  const { data: GA4MeasurementData } = useMeasurementsQuery({ providerId: GA4_PROVIDER_ID });
  const { data: translationData } = useTranslationsQuery();
  const translations = translationData?.translations || [];

  const { measurementsByProvider, conversionsByProvider, reset: resetContentMappingStore } = useContentMappingStore();
  const measurements = Object.values(measurementsByProvider?.[providerId] ?? {}).flat();
  const conversions: NexoyaConversion[] = Object.values(conversionsByProvider?.[providerId] ?? {}).flat();
  const GA4Measurements: Partial<NexoyaMeasurement[]> = GA4MeasurementData?.measurements;
  const mergedMeasurements = concat(measurements, GA4Measurements);
  const isCustomConversionsOptionDisabled = !adAccountIds?.length || !conversions?.length;

  const setMeasurementsByProvider = useContentMappingStore((state) => state.setMeasurementsByProvider);
  const setConversionsByProvider = useContentMappingStore((state) => state.setConversionsByProvider);
  const isAssigningMetricsFirstTime = contentRule?.funnelStepMappings?.length === 0;

  const [fetchMeasurements] = useLazyQuery(MEASUREMENTS_QUERY);
  const [fetchConversions] = useLazyQuery(LIST_CONVERSIONS_QUERY);
  const { setClashingDiscoveredContents, selectedRules, resetSelectedRules } = useRuleClashStore();

  const {
    isOpen: isOpenApplyDialog,
    toggleDialog: toggleApplyDialog,
    closeDialog: closeApplyDialog,
  } = useDialogState();

  const {
    isOpen: isOpenClashesDialog,
    toggleDialog: toggleClashesDialog,
    closeDialog: closeClashesDialog,
  } = useDialogState();

  const [applyRulesToDiscoveredContents, { loading: loadingApply }] = useApplyRulesToDiscoveredContentsMutation({
    portfolioId,
    status: NexoyaDiscoveredContentStatus.Manual,
  });
  const [updateContentRule, { loading: loadingUpdate }] = useUpdateContentRuleMutation({ portfolioId });
  const [setContentRuleMappingMutation, { loading }] = useSetContentRuleMappingMutation({
    portfolioId,
    onCompleted: (data) => {
      const { clashingDiscoveredContents } = data.setContentRuleMappingAndApplyToContents;

      if (clashingDiscoveredContents?.length) {
        setClashingDiscoveredContents(clashingDiscoveredContents);
        toast.warning('Some contents have clashes');
        toggleClashesDialog();
      } else {
        toast.success(`Metrics applied successfully`, {
          description: 'The metrics have been applied to all matching contents',
        });
        closeSidePanel();
      }
    },
  });

  useEffect(() => {
    const firstFunnelStep = funnelSteps[0];
    setSelectedFunnelStep({
      title: firstFunnelStep.title,
      funnel_step_id: firstFunnelStep.funnelStepId,
      type: firstFunnelStep.type,
    });
  }, []);

  useEffect(() => {
    if (contentRule) {
      setAssignedMetrics(
        contentRule.funnelStepMappings.map((contentRuleFunnelStepMapping) => {
          const mapping = contentRuleFunnelStepMapping.mapping;

          return {
            funnelStepId: contentRuleFunnelStepMapping.funnelStepId,
            metricId: mapping.metricId,
            type: mapping.type,
            conversions: mapping.conversions?.map(({ __typename, ...rest }) => rest),
            utmParams: mapping.utmParams,
          };
        }),
      );
    }
  }, [contentRule]);

  // Fetch measurements and conversions for each provider
  useEffect(() => {
    if (didFetch.current || !adAccountIds?.length) return;

    didFetch.current = true;

    const fetchData = async () => {
      const { data: measurementsData } = await fetchMeasurements({
        variables: { providerId },
      });
      if (measurementsData?.measurements) {
        setMeasurementsByProvider(providerId, measurementsData.measurements);
      }

      fetchConversions({
        variables: { adAccountContentIds: adAccountIds, teamId },
      }).then(({ data }) => {
        if (data?.listConversions) {
          setConversionsByProvider(providerId, data.listConversions);
        }
      });
    };

    fetchData();
  }, [
    providerId,
    adAccountIds,
    teamId,
    fetchMeasurements,
    fetchConversions,
    setMeasurementsByProvider,
    setConversionsByProvider,
  ]);

  // Build funnel data
  useEffect(() => {
    setFunnelData(
      funnelSteps.reduce(
        (acc, step) => ({
          labels: [...acc.labels, step.title],
          subLabels: [...acc.subLabels, step.title],
          values: [...acc.values, [100]],
          colors: [...acc.colors, DEFAULT_COLOR],
        }),
        INITIAL_DATA,
      ),
    );
  }, [funnelSteps]);

  // Initialize assigned metric for the selected funnel step
  useEffect(() => {
    if (selectedFunnelStepId == null) return;
    setAssignedMetrics((prev) => {
      if (prev.some((metric) => metric.funnelStepId === selectedFunnelStepId)) return prev;
      return [
        ...prev,
        {
          funnelStepId: selectedFunnelStepId,
          type: null,
          metricId: null,
          conversionId: null,
          conversionName: null,
        },
      ];
    });
  }, [selectedFunnelStepId]);

  const handleUpdate = () => {
    const funnelStepMappings: NexoyaContentRuleFunnelStepMappingInput[] = assignedMetrics
      ?.filter((m) => m.type)
      ?.map(({ funnelStepId, metricId, conversions, utmParams, type }) => ({
        funnelStepId: Number(funnelStepId),
        mapping: {
          type,
          ...(metricId && { metricId }),
          conversions: conversions?.map(({ accountConversionIds, metricId, conversionName }) => ({
            metricId,
            conversionName,
            accountConversionIds,
          })),
          utmParams: cleanUtmParams(utmParams),
        },
      }));

    updateContentRule({
      variables: {
        teamId,
        portfolioId,
        contentRuleId: contentRule?.contentRuleId,
        contentRuleEdit: {
          funnelStepMappings,
        },
      },
    }).then(() => {
      closeSidePanel();
    });
  };

  const handleSubmit = () => {
    setContentRuleMappingMutation({
      variables: {
        contentRuleId: contentRule.contentRuleId,
        portfolioId,
        teamId,
        funnelStepMappings:
          assignedMetrics
            ?.filter((mapping): mapping is NonNullable<typeof mapping> => Boolean(mapping?.type))
            ?.map(({ funnelStepId, metricId, conversions, utmParams, type }) => {
              const mapping: NexoyaFunnelStepMappingInput = {
                type,
                ...(metricId && { metricId }),
                conversions:
                  conversions?.map(({ accountConversionIds, metricId, conversionName }) => ({
                    metricId,
                    conversionName,
                    accountConversionIds,
                  })) ?? [],
              };

              if (utmParams?.length) {
                mapping.utmParams = [...cleanUtmParams(utmParams)];
              }

              return { funnelStepId: Number(funnelStepId), mapping };
            }) ?? [],
      },
    }).then(() => {
      closeApplyDialog();
    });
  };

  const handleApplyClashes = async () => {
    const discoveredContentsWithRulesToApply = Object.entries(selectedRules).map(([contentId, rule]) => ({
      discoveredContentId: parseInt(contentId, 10),
      contentRuleId: toNumber(rule.ruleId),
      impactGroupRuleId: null,
    }));

    await applyRulesToDiscoveredContents({
      variables: {
        discoveredContentsWithRulesToApply,
        portfolioId,
        teamId,
      },
    }).then(() => {
      closeClashesDialog();
      resetSelectedRules();
      closeSidePanel();
    });
  };

  const handleClose = () => {
    resetContentMappingStore();
    didFetch.current = false;
    closeSidePanel();
  };

  const handleMetricTypeChange = (type: NexoyaFunnelStepMappingType) => {
    if (!selectedFunnelStepId) return;

    setAssignedMetrics((prev) =>
      prev.map((metric) => {
        if (metric.funnelStepId !== selectedFunnelStepId) return metric;

        const updatedMetric = {
          ...metric,
          type,
        };

        if (type === NexoyaFunnelStepMappingType.Metric) {
          return {
            ...updatedMetric,
            conversions: [],
            utmParams: [],
          };
        } else if (type === NexoyaFunnelStepMappingType.Ignore) {
          return {
            ...updatedMetric,
            type: NexoyaFunnelStepMappingType.Ignore,
            metricId: null,
            conversions: [],
            utmParams: [],
          };
        } else if (type === NexoyaFunnelStepMappingType.Conversion) {
          return {
            ...updatedMetric,
            type: NexoyaFunnelStepMappingType.Conversion,
            metricId: null,
            conversions: [{ accountConversionIds: [], conversionName: '', metricId: null }],
            utmParams: [],
          };
        }

        return updatedMetric;
      }),
    );
  };

  const getSelectedMeasurementForFunnelStepId = (
    funnelStepId: number,
    measurementsList: Partial<NexoyaMeasurement>[],
  ) =>
    measurementsList.find(
      (m) => m?.measurement_id === assignedMetrics.find((a) => a.funnelStepId === funnelStepId)?.metricId,
    );

  const getNextOrPreviousFunnelStep = (direction: 'next' | 'previous') => {
    const currentIndex = funnelSteps.findIndex((step) => step.funnelStepId === selectedFunnelStepId);
    return funnelSteps[direction === 'next' ? currentIndex + 1 : currentIndex - 1];
  };

  const renderAssignedMetricCombobox = (measurementsList: Partial<NexoyaMeasurement>[], triggerClassName?: string) => {
    const selectedMetricName = translate(
      translations,
      getSelectedMeasurementForFunnelStepId(selectedFunnelStepId, measurementsList)?.name,
    );
    return (
      <Popover open={isAssignMetricsOpen} onOpenChange={setIsAssignMetricsOpen}>
        <PopoverTrigger asChild>
          <div
            className={cn(
              'w-52 whitespace-pre rounded-md border border-neutral-100 bg-white p-2 shadow-sm',
              triggerClassName,
            )}
          >
            <span className="block truncate">
              {selectedMetricName === 'No data' ? 'Select a metric' : selectedMetricName}
            </span>
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0" align="start">
          <Command>
            <CommandInput placeholder="Search metrics..." />
            <CommandList>
              <CommandEmpty>No metrics found.</CommandEmpty>
              <CommandGroup>
                {measurementsList
                  .filter((measurement) => measurement.optimization_target_type?.includes(selectedFunnelStep?.type))
                  .map((measurement) => (
                    <CommandItem
                      key={measurement.measurement_id}
                      value={translate(translations, measurement.name)}
                      onSelect={() => {
                        setAssignedMetrics((prev) =>
                          prev.map((metric) =>
                            metric.funnelStepId === selectedFunnelStepId
                              ? {
                                  ...metric,
                                  metricId: toNumber(measurement.measurement_id),
                                }
                              : metric,
                          ),
                        );
                        setIsAssignMetricsOpen(false);
                      }}
                    >
                      <span>{translate(translations, measurement.name)}</span>
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  };

  const renderInputsBasedOnMappingType = (mappingType?: NexoyaFunnelStepMappingType) => {
    switch (mappingType) {
      case NexoyaFunnelStepMappingType.Metric:
        return (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="mt-3 text-lg text-neutral-900">Assign a metric</div>
              <div className="mt-0.5 text-sm font-light text-neutral-400">
                Assign a metric for the conversion goal selected above.
              </div>
            </div>
            {renderAssignedMetricCombobox(measurements)}
          </div>
        );
      case NexoyaFunnelStepMappingType.Conversion:
        return (
          <CustomConversions
            selectedFunnelStepId={selectedFunnelStepId}
            selectedConversions={
              assignedMetrics.find((m) => m.funnelStepId === selectedFunnelStepId)?.conversions || []
            }
            conversions={conversions}
            measurements={measurements}
            translations={translations}
            setAssignedMetrics={setAssignedMetrics}
          />
        );
      case NexoyaFunnelStepMappingType.Utm:
        return (
          <UTMTracking
            assignedMetrics={assignedMetrics}
            setAssignedMetrics={setAssignedMetrics}
            renderAssignedMetricCombobox={(triggerClassName) =>
              renderAssignedMetricCombobox(GA4Measurements, triggerClassName)
            }
            funnelStepId={selectedFunnelStepId}
            selectedMetricId={assignedMetrics.find((m) => m.funnelStepId === selectedFunnelStepId)?.metricId}
          />
        );
      default:
        return null;
    }
  };

  const selectedMappingType = assignedMetrics.find((m) => m.funnelStepId === selectedFunnelStepId)?.type;
  const hasAnyMetricAssignment = assignedMetrics.some((m) => m.type);

  return (
    <>
      <SidePanel
        isOpen={isOpen}
        onClose={handleClose}
        paperProps={{ style: { width: 'calc(100% - 218px)', paddingBottom: '78px' } }}
      >
        <div className="border border-b-[#eaeaea] px-6 py-5">
          <h3 className="text-xl font-medium text-neutral-900">
            Assign metrics to funnel steps for {contentRule?.name}
          </h3>
        </div>
        <div className="flex h-full gap-10 px-6">
          <div className="w-fit flex-col">
            <div className="mb-8 mt-6 text-sm font-light text-neutral-500">
              Configure the metric mappings for each funnel step.
            </div>
            <FunnelContainerStyled>
              <LabelsContainerStyled>
                <AssignMetricFunnelStepLabel
                  assignedMetrics={assignedMetrics}
                  mergedMeasurements={mergedMeasurements}
                  translations={translations}
                  funnelSteps={funnelSteps}
                  getSelectedMeasurementForFunnelStepId={(funnelStepId: number) =>
                    getSelectedMeasurementForFunnelStepId(funnelStepId, mergedMeasurements)
                  }
                />
              </LabelsContainerStyled>
              <FunnelStepsContainerStyled>
                <FunnelSteps withTooltip={false} funnelData={funnelData} funnelSteps={funnelSteps} />
              </FunnelStepsContainerStyled>
            </FunnelContainerStyled>
          </div>
          <div className="h-full w-[1px] bg-neutral-100" />
          <div className="flex h-full flex-col gap-6">
            <div>
              <div className="mt-6 text-lg text-neutral-700">
                Mapping type for <span className="font-semibold">{capitalize(selectedFunnelStep?.title)}</span>
              </div>
              <div className="mt-2 text-sm font-light text-neutral-500">
                Assign metrics to funnel steps to track the performance of your marketing campaigns.
              </div>
              <RadioGroup className="ml-[-3px] mt-4 flex flex-col gap-1.5">
                {ASSIGN_METRIC_OPTIONS(isCustomConversionsOptionDisabled).map((option) => (
                  <div key={option.value} className="flex items-center gap-2">
                    <FormControlLabel
                      checked={option.value === selectedMappingType}
                      // @ts-ignore
                      onChange={() => !option.disabled && handleMetricTypeChange(option.value)}
                      value={option.value}
                      label={option.label}
                      control={<Radio />}
                      data-cy={option.value}
                      disabled={option.disabled}
                    />
                  </div>
                ))}
              </RadioGroup>
            </div>
            <div>{renderInputsBasedOnMappingType(selectedMappingType)}</div>
          </div>
        </div>
        <SidePanelActions className="!fixed bottom-0 z-[3400] !w-[calc(100%-218px)] border-t border-neutral-100">
          <Button
            id="previous"
            disabled={loading || !hasAnyMetricAssignment}
            onClick={toggleApplyDialog}
            variant="contained"
            color="secondary"
            className="w-fit"
          >
            Save progress and apply
          </Button>
          <div className="flex justify-end gap-3">
            <Button
              id="previous"
              variant="contained"
              onClick={() => {
                const previousFunnelStep = getNextOrPreviousFunnelStep('previous');
                if (previousFunnelStep) {
                  setSelectedFunnelStep({
                    title: previousFunnelStep.title,
                    funnel_step_id: previousFunnelStep.funnelStepId,
                    type: previousFunnelStep.type,
                  });
                }
              }}
            >
              Previous step
            </Button>
            <ButtonAsync
              id="next"
              variant="contained"
              color="primary"
              loading={loading}
              disabled={loading}
              onClick={() => {
                const nextFunnelStep = getNextOrPreviousFunnelStep('next');
                if (!nextFunnelStep) {
                  toggleApplyDialog();
                } else {
                  setSelectedFunnelStep({
                    title: nextFunnelStep.title,
                    funnel_step_id: nextFunnelStep.funnelStepId,
                    type: nextFunnelStep.type,
                  });
                }
              }}
            >
              {getNextOrPreviousFunnelStep('next') ? 'Next funnel step' : 'Review and apply'}
            </ButtonAsync>
          </div>
        </SidePanelActions>

        <AlertDialog open={isOpenApplyDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Review and apply metrics</AlertDialogTitle>
              <AlertDialogDescription>
                <span className="mt-1 text-sm font-normal leading-5 text-neutral-400">
                  Here’s a summary of your assigned metrics per funnel step.
                </span>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <div className="rounded-lg border border-neutral-100 bg-seasalt">
              <div className="grid grid-cols-2 p-6 py-2">
                <LabelLight className="!mb-0">Funnel step</LabelLight>
                <LabelLight className="!mb-0">Assigned metric</LabelLight>
              </div>
              <div className="h-[1px] w-full bg-neutral-100" />
              <div className="flex">
                <div className="grid grid-cols-1 p-6 py-2">
                  {funnelSteps.map((funnelStep) => (
                    <div key={funnelStep.funnelStepId} className="text-md py-3 pr-8 font-normal text-neutral-900">
                      {capitalize(funnelStep.title)}
                    </div>
                  ))}
                </div>
                <div className="h-auto w-[1px] bg-neutral-100" />
                <div className="grid grid-cols-1 p-7 py-2">
                  {funnelSteps.map((funnelStep) => {
                    const { assignedMetricName, type, mappingTypeLabel } = getAssignedMetricBasedOnMappingType({
                      funnelStepId: funnelStep.funnelStepId,
                      assignedMetrics,
                      mergedMeasurements,
                      getSelectedMeasurementForFunnelStepId,
                      translations,
                    });
                    return (
                      <div key={funnelStep.funnelStepId} className="flex flex-col">
                        <div
                          className={cn(
                            'text-md py-3 pr-8 font-normal',
                            type === NexoyaFunnelStepMappingType.Ignore ? 'text-neutral-400' : 'text-neutral-900',
                          )}
                        >
                          {assignedMetricName === 'No data' ? 'Multiple custom conversions' : assignedMetricName} <br />
                        </div>
                        <LabelLight className="!text-[11px] !text-neutral-300">
                          Mapping type: {mappingTypeLabel}
                        </LabelLight>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <AlertDialogFooter>
              <AlertDialogAction>
                <ButtonAsync
                  onClick={closeApplyDialog}
                  loading={loading || loadingUpdate}
                  disabled={loading || loadingUpdate}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  Go back
                </ButtonAsync>
              </AlertDialogAction>
              <AlertDialogAction>
                <ButtonAsync
                  onClick={isAssigningMetricsFirstTime ? handleSubmit : handleUpdate}
                  loading={loading || loadingUpdate}
                  disabled={loading || loadingUpdate || !hasAnyMetricAssignment}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Apply metrics
                </ButtonAsync>
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        {isOpenClashesDialog && (
          <MetricClashesDialog<NexoyaApplicableContentRule>
            isOpen={isOpenClashesDialog}
            onCancel={closeClashesDialog}
            onConfirm={() => handleApplyClashes()}
            loading={loadingApply}
            getRules={(dsc) => dsc.contentRules}
            getRuleId={(rule) => rule?.contentRule?.contentRuleId?.toString()}
            getRuleName={(rule) => rule?.contentRule?.name}
            dialogTitle="Content rule clashes"
            type="content rule"
          />
        )}
      </SidePanel>
    </>
  );
};
